import { createApp } from 'vue'

import { VueFire, VueFireAuth } from 'vuefire'

import { firebaseApp } from './firebase'

import App from './App.vue'
import router from './router'

import credi from './plugins/credi'

import './assets/css/global.css'

createApp(App)
  .use(credi)
  .use(router)
  .use(VueFire, {
    firebaseApp,
    modules: [VueFireAuth()]
  })
  .mount('#app')
