<template>
    <div id="box">
        <ol class="flex flex-col">
            <li
              v-for="(msg, index) in msgs"
              :key="index"
              :msg="msg.uuid"
              :class="{ 'self-end flex-row-reverse': !msg.bot }"
              class="mb-4 flex items-end gap-2">
                <img class="h-9 shadow-md rounded-full translate-y-3.5" :src="msg.image" alt="Profile" />
                <div class="flex flex-col gap-2" :class="{ 'items-end': !msg.bot }">
                  <div v-if="!msg.texts.length" class="box-msg">
                    <img class="h-6" src="@/assets/img/icons/loading.svg" alt="Typing" />
                  </div>
                  <div
                    v-for="text in msg.texts"
                    :key="text" :title="msg.time"
                    :class="`last:rounded-${!msg.bot ? 'br' : 'bl'}-sm`"
                    class="box-msg">
                    <div v-html="text" />
                  </div>
                </div>
            </li>
        </ol>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { v4 as uuidv4 } from 'uuid'

import { Message, MessageQuestion, PayloadCommunicate } from '@/typing'

export default defineComponent({
  data () {
    return {
      msgs: [] as Message[]
    }
  },
  name: 'BoxComponent',
  methods: {
    async onCommunicate ({ event, data }: PayloadCommunicate) {
      this[`on${event}`](data)
    },
    async onReplyQuestion (data: { question: MessageQuestion, value: string }) {
      this.sendMessage({
        texts: [data.value]
      })

      const { response } = this.msgs.find(m => m.uuid === data.question.uuid)!

      if (response?.resolve) response.resolve(data.value)

      this.$emit('communicate', {
        to: 'send',
        event: 'DoneQuestion'
      })
    },
    async onPushMessage () {
      //
    },
    async sendMessage (m: Message | any) {
      const uuid: string = uuidv4()

      const time: string = (new Date()).toLocaleDateString('pt-BR', { hour: '2-digit', minute: '2-digit' })

      if (!m.bot) m.bot = !!m.response

      const image: string = `/img/${m.bot ? 'robot' : 'user'}.svg`

      const index: number = this.msgs.push({ ...m, uuid, image, time, bot: m.bot, texts: [] }) - 1

      if (m.response) {
        this.msgs[index].response!.promise = new Promise((resolve) => {
          this.msgs[index].response!.resolve = resolve
        })
      }

      for (let tt = 0; tt < m.texts.length; tt++) {
        setTimeout(() => {
          if ((tt + 1) === m.texts.length && m.response) this.sendQuestion(this.msgs[index])

          if (this.msgs[index]?.texts) {
            this.msgs[index].texts.push(`<p>${m.texts[tt]}</p>`)

            this.onPushMessage()
          }
        }, (tt + 1) * 500)
      }

      return this.msgs[index].response?.promise || Promise.resolve()
    },
    async clearMessages () {
      this.msgs = []

      this.$emit('communicate', {
        to: 'send',
        event: 'DoneQuestion'
      })
    },
    async sendQuestion (m: Message) {
      this.$emit('communicate', {
        to: 'send',
        event: 'NewQuestion',
        data: {
          uuid: m.uuid,
          placeholder: m.response?.placeholder
        }
      })
    }
  }
})
</script>

<style scoped>
#box {
  @apply m-4 overflow-y-auto;
}

.box-msg {
  @apply p-4 w-fit max-w-[40vw] bg-tertiary shadow-sm rounded-md;
}
</style>
