import type { FirebaseOptions } from 'firebase/app'

export const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: 'AIzaSyDbJqlr3hw5pU6HuZILt-uTXkBfaIl5dOI',
  authDomain: 'consulta-1a96f.firebaseapp.com',
  databaseURL: 'https://consulta-1a96f-default-rtdb.firebaseio.com',
  projectId: 'consulta-1a96f',
  storageBucket: 'consulta-1a96f.appspot.com',
  messagingSenderId: '682292423908',
  appId: '1:682292423908:web:be9e4963ec9938dce4d0f1',
  measurementId: 'G-HV3994KPK5'
}

export const WANOTIFY: string = '+553597322539'

export const WASUPPORT: string = '+553597322539'

export const PROXY_URL: string = 'https://backconsulta.credi.net'

export const CONSULT_PRICE: number = 1
