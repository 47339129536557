<template>
    <div id='recaptcha' />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { RecaptchaVerifier } from 'firebase/auth'

export default defineComponent({
  async created () {
    if (window.recaptchaVerifier) window.recaptchaVerifier.clear()
  },
  async mounted () {
    window.recaptchaVerifier = new RecaptchaVerifier((this.$root as any).auth, 'recaptcha', {
      size: 'invisible'
    })

    window.recaptchaVerifier.render()
  },
  name: 'RecaptchaComponent'
})
</script>

<style scoped>
#recaptcha {
  @apply fixed right-0 -translate-x-3 -translate-y-[83.6vh];
}
</style>
