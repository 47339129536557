<template>
    <div id="header">
      <div class="flex gap-2 items-center">
        <img class="h-14" src="@/assets/img/robot.svg" alt="Robot">
        <div class="leading-[1.1rem]">
          <h2 class="font-bold">Atendimento</h2>
          <small class="opacity-60">online</small>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HeaderComponent'
})
</script>

<style scoped>
#header {
  @apply px-4 py-2 text-white bg-secondary shadow-[0_0_5px_0_black];
}
</style>
