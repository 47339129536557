<template>
    <div v-show="active" id="ddi">
      <div class="p-4 bg-secondary rounded-md">
        <h2 class="mb-4 text-xl text-tertiary text-center">Qual é sua bandeira?</h2>
        <div class="flex flex-col gap-2">
          <div class="flex">
            <div class="p-2 min-w-20 bg-primary flex justify-center items-center rounded-l">
              <img class="h-6" :src="country.image" alt="Flag" :title="country.name">
              <span class="ml-1.5 font-bold">+{{ country.code }}</span>
            </div>
            <input v-model="search" class="grow pl-1.5 rounded-r" type="text" list="listCountries" placeholder="Qual é sua bandeira?">
            <datalist id="listCountries">
              <option v-for="country in countryDisplay" :key="country.short" :value="country.name" />
            </datalist>
          </div>
          <button @click="onConfirm" class="grow p-2 bg-blue-600 text-primary rounded">
            <span>Confirmar</span>
          </button>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Country } from '@/typing'

import countryCodes from 'country-codes-list'

type Resolve = (value: Country | PromiseLike<Country>) => void

export default defineComponent({
  data () {
    return {
      search: 'Brasil',
      active: false,
      resolve: undefined as Resolve | undefined
    }
  },
  setup () {
    const countries: Country[] = Object.entries((countryCodes as any).customList('countryCode', '{countryNameLocal},{countryCallingCode}'))
      .map(([short, details]) => {
        const cf: string[] = (details as string).split(',')

        return { short, code: cf[1], name: cf[0], image: '' }
      })

    const country: Country = countries.find(c => c.short === 'BR')!

    return { countries, country }
  },
  created () {
    this._updateFlag()
  },
  name: 'CountryComponent',
  watch: {
    search (to) {
      const exist: Country | undefined = this.countries
        .find(({ name }) => name.toLowerCase() === to.toLowerCase())

      if (!exist) return

      this.country = exist

      this._updateFlag()
    }
  },
  computed: {
    countryDisplay (): Country[] {
      if (!this.search) return []

      return this.countries
        .filter(({ name }) => {
          const search: string = this.search.toLowerCase()

          return name.toLowerCase().startsWith(search) && name.toLowerCase() !== search
        })
        .slice(0, 10)
    }
  },
  methods: {
    async onConfirm () {
      if (this.resolve) this.resolve(this.country)

      this.active = false
    },
    async _updateFlag () {
      this.country = {
        ...this.country,
        image: (this as any).$credi.getFlagSVG(this.country.short)
      }
    },
    async newCountry () {
      this.active = true

      const promise: Promise<Country> = new Promise((resolve: Resolve) => {
        this.resolve = resolve
      })

      return promise
    },
    getCountry () {
      return this.country
    }
  }
})
</script>

<style scoped>
#ddi {
  @apply z-10 fixed top-0 left-0 w-screen h-screen bg-primary bg-opacity-80 flex items-center justify-center;
}
</style>
