<template>
  <main>
    <router-view />
    <recaptcha-component />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { useFirebaseAuth } from 'vuefire'

import RecaptchaComponent from '@/components/RecaptchaComponent.vue'

export default defineComponent({
  setup () {
    return {
      auth: useFirebaseAuth()
    }
  },
  name: 'App',
  components: {
    RecaptchaComponent
  }
})
</script>
