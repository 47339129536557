import { initializeApp } from 'firebase/app'
import { getFirestore, collection } from 'firebase/firestore'

import { FIREBASE_CONFIG } from '@/environment'

export const firebaseApp = initializeApp(FIREBASE_CONFIG)

const db = getFirestore(firebaseApp)

export const usersRef = collection(db, 'users')
