import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_recaptcha_component = _resolveComponent("recaptcha-component")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_router_view),
    _createVNode(_component_recaptcha_component)
  ]))
}