<template>
  <div id="chat">
    <header-component />
    <box-component @communicate="onEventCommunicate" ref="box" class="grow" />
    <send-component @communicate="onEventCommunicate" ref=send />
    <country-component ref="country" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import { DocumentSnapshot } from 'firebase/firestore'
import { signInWithPhoneNumber, Auth } from 'firebase/auth'

import { cnpj as vCNPJ, cpf as vCPF } from 'cpf-cnpj-validator'
import parsePhoneNumber, { CountryCode, PhoneNumber } from 'libphonenumber-js'

import { WASUPPORT, CONSULT_PRICE } from '@/environment'
import { PayloadCommunicate, RootComponent } from '@/typing'

import BoxComponent from './Chat/BoxComponent.vue'
import SendComponent from './Chat/SendComponent.vue'
import HeaderComponent from './Chat/HeaderComponent.vue'
import CountryComponent from './Chat/CountryComponent.vue'

export default defineComponent({
  setup () {
    const wa: URL = new URL(`https://wa.me/${WASUPPORT}`)

    wa.searchParams.append('text', 'Olá, eu gostaria de adicionar saldo.')

    return {
      box: ref<typeof BoxComponent>(),
      send: ref<typeof SendComponent>(),
      country: ref<typeof CountryComponent>(),
      waSupport: wa.toString()
    }
  },
  mounted () {
    this.box?.sendMessage({
      texts: ['Bem-vindo ao sistema <strong>TEXTO</strong> de consultas, vamos pedir algumas informações para autenticar você.'],
      bot: true
    }).then(() => {
      this.onChat()
    })
  },
  name: 'HomeView',
  components: {
    BoxComponent,
    SendComponent,
    HeaderComponent,
    CountryComponent
  },
  methods: {
    async onEventCommunicate (payload: PayloadCommunicate) {
      this[payload.to].onCommunicate(payload)
    },
    async onChat () {
      const phone: PhoneNumber | undefined = await this.onChatAuthenticate()

      if (!phone) { this.onChat(); return }

      await this.onChatConsulting(phone)

      await this.onChatBalance()

      this.onChat()
    },
    async onChatConsulted (user: DocumentSnapshot) {
      let msgBalance: string = `<br><small>Você possui <strong>${user.get('balance')}R$</strong> de saldo. ` + '%free%' + '</small>'

      msgBalance = msgBalance.replace('%free%', user.get('free') ? '<strong>PRIMEIRA CONSULTA GRATIS!</strong>' : '')

      let value: string = await this.box?.sendMessage({
        texts: [
          'Informe o <strong>CPF/CNPJ</strong> para realizar uma consulta. (Somente números)' + msgBalance,
          `<a class='text-blue-600 underline' href='${this.waSupport}' target='_blank' title='Suporte'>🤙 Adicionar saldo</a>`
        ],
        response: { placeholder: 'Insira seu CPF ou CNPJ' }
      })

      let type: string | null

      while (true) {
        type = vCPF.isValid(value) ? 'cpf' : (vCNPJ.isValid(value) ? 'cnpj' : null)

        if (type) break

        value = await this.box?.sendMessage({
          texts: ['O <strong>CPF/CNPJ</strong> inserido é inválido, tente novamente.'],
          response: { placeholder: 'Insira seu CPF ou CNPJ' }
        })
      }

      return { value, type }
    },
    async onChatConsulting (phone: PhoneNumber) {
      while (true) {
        const user: DocumentSnapshot = await (this as any).$credi.getUser(phone.number)

        const consulted: { value: string, type: string } = await this.onChatConsulted(user) // DEPRECIED consulted.type

        if (user.get('balance') <= 0 && !user.get('free')) break

        if (user.get('free')) {
          await this.box?.sendMessage({
            texts: ['Você acabou de utilizar seu uso gratuito!<br>Próxima consulta você deverá adicionar saldo.'],
            bot: true
          })

          await (this as any).$credi.updateUser(phone.number, { free: false })
        } else {
          await (this as any).$credi.updateUser(phone.number, {
            balance: user.get('balance') - CONSULT_PRICE
          })
        }

        const urlPDF = await (this as any).$credi.getConsultPDF(consulted.value)

        if (!urlPDF) {
          await this.box?.sendMessage({
            texts: ['Ocorreu um error ao tratarmos de suas informações, tente novamente mais tarde.'],
            bot: true
          })

          return undefined
        }

        const date = (new Date()).toLocaleDateString('pt-BR', { hour: '2-digit', minute: '2-digit' })

        await this.box?.sendMessage({
          texts: [
          `Senhor(a), aqui estão suas informações de consultas às <strong>${date}</strong>.` +
          ` <a class='text-blue-600 underline' href='${urlPDF}' target='_blank' title='Visualizar PDF'>📄 CLIQUE AQUI!</a>`
          ],
          bot: true
        })
      }
    },
    async onChatBalance () {
      await this.box?.sendMessage({
        texts: [
          'Parece que você não tem saldo, adicione saldo entrando em contato abaixo.',
          `<a class='text-blue-600 underline' href='${this.waSupport}' target='_blank' title='Suporte'>🤙 Adicionar saldo</a>`
        ],
        bot: true
      })

      let confirm: string = await this.box?.sendMessage({
        texts: ['Caso queira realizar outra tentativa de consulta digite <strong>REPETIR</strong>.'],
        response: { placeholder: 'Confirme' }
      })

      while (true) {
        if (confirm === 'REPETIR') break

        confirm = await this.box?.sendMessage({
          texts: ['Não entendi sua resposta, digite <strong>REPETIR</strong> caso queira realizar outra consulta.'],
          response: { placeholder: 'Confirme' }
        })
      }
    },
    async onChatAuthenticate () {
      let phone: PhoneNumber | undefined

      const country = this.country?.getCountry()

      const inpt: string = await this.box?.sendMessage({
        texts: [
          'Para que possamos identificar sua identidade poderia enviar seu número de telefone? <small class="font-semibold">Ex.: (DDD) <span class="font-extrabold">9</span>9999-9999</small>',
          `Você não é do(a) ${country.name}? Digite <strong>INTERNACIONAL</strong> para selecionar sua bandeira.`
        ],
        response: { placeholder: 'Insira seu telefone' }
      })

      if (inpt.toUpperCase() === 'INTERNACIONAL') {
        await this.country?.newCountry()

        return undefined
      }

      phone = parsePhoneNumber(inpt, country.short as CountryCode)

      while (true) {
        if (phone?.isValid()) break

        phone = parsePhoneNumber(
          await this.box?.sendMessage({
            texts: ['O telefone inserido não existe ou está incorreto, verifique todos os dígitos. Tente novamente!<br><br><small class="font-semibold">Ex.: (DDD) <span class="font-extrabold">9</span>9999-9999</small>'],
            response: { placeholder: 'Insira seu telefone' }
          }), country.short as CountryCode)
      }

      const auth: Auth = (this.$root as RootComponent).auth

      try {
        window.validatorCode = await signInWithPhoneNumber(auth, phone!.number, window.recaptchaVerifier)
      } catch {
        await this.box?.sendMessage({
          texts: ['Ocorreu um erro ao enviar o <strong>SMS</strong> para seu telefone!'],
          bot: true
        })

        return undefined
      }

      let code: string = await this.box?.sendMessage({
        texts: [`Enviamos um <strong>SMS</strong> para o telefone <strong>${phone!.number}</strong>, confirme o código por gentileza.`],
        response: { placeholder: 'Insira seu código SMS' }
      })

      try {
        await window.validatorCode.confirm(code)
      } catch {
        while (true) {
          code = await this.box?.sendMessage({
            texts: ['O código inserido é inválido, tente novamente.<br>Caso o número esteja incorreto digite <strong>REPETIR</strong> para redefinir.'],
            response: { placeholder: 'Insira seu código SMS' }
          })

          if (code.toUpperCase() === 'REPETIR') return undefined

          try {
            await window.validatorCode.confirm(code)

            break
          } catch {
            continue
          }
        }
      }

      await this.box?.sendMessage({
        texts: ['Código validado com sucesso!'],
        bot: true
      })

      return phone
    }
  }
})
</script>

<style scoped>
#chat {
  @apply h-screen flex flex-col;
}
</style>
