import axios from 'axios'

import { Plugin } from 'vue'

import { DocumentSnapshot, DocumentReference, updateDoc, getDoc, setDoc, doc } from 'firebase/firestore'

import { usersRef } from '@/firebase'
import { PROXY_URL, WANOTIFY } from '@/environment'

const Credi: Plugin = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install (app, ...options) {
    app.config.globalProperties.$credi = {
      async getConsultPDF (value: string): Promise<string | undefined> {
        try {
          return (await axios.get(`${PROXY_URL}/consult`, {
            params: { value },
            headers: {
              'Content-Type': 'application/json'
            }
          })).data.pdf
        } catch {
          return undefined
        }
      },
      async notifyWA (msg: string): Promise<boolean> {
        return axios.post(`${PROXY_URL}/mpwa`, { phone: WANOTIFY, msg })
          .then(res => res.data.status)
          .catch(() => false)
      },
      async getUser (phone: string): Promise<DocumentSnapshot> {
        const ref: DocumentReference = doc(usersRef, phone)

        if (!(await getDoc(ref)).exists()) {
          await this.notifyWA(`${phone} cadastrado`)

          await setDoc(ref, {
            balance: 0,
            free: true
          })
        }

        return getDoc(ref)
      },
      async updateUser (phone: string, payload: any): Promise<boolean> {
        const ref: DocumentReference = doc(usersRef, phone)

        return updateDoc(ref, payload)
          .then(() => true)
          .catch(() => false)
      },
      getFlagSVG (countryShort: string): string {
        return `http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryShort}.svg`
      }
    }
  }
}

export default Credi
