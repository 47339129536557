import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5e2254fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "chat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_box_component = _resolveComponent("box-component")!
  const _component_send_component = _resolveComponent("send-component")!
  const _component_country_component = _resolveComponent("country-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_component),
    _createVNode(_component_box_component, {
      onCommunicate: _ctx.onEventCommunicate,
      ref: "box",
      class: "grow"
    }, null, 8, ["onCommunicate"]),
    _createVNode(_component_send_component, {
      onCommunicate: _ctx.onEventCommunicate,
      ref: "send"
    }, null, 8, ["onCommunicate"]),
    _createVNode(_component_country_component, { ref: "country" }, null, 512)
  ]))
}