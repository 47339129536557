<template>
    <div id="send">
      <input v-if="!question" class="send-ipt" type="text" placeholder="..." disabled />
      <input v-else @keyup.enter="onSend" class="send-ipt" type="text" :placeholder="question.placeholder" />
      <!---->
      <button @click="onSend" class="ml-2 p-3 bg-secondary rounded-full">
        <img class="translate-x-0.5 h-6 hover:animate-pulse" src="@/assets/img/icons/send.svg" alt="Confirm" />
      </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { PayloadCommunicate, MessageQuestion } from '@/typing'

export default defineComponent({
  data () {
    return {
      question: null as MessageQuestion | null
    }
  },
  name: 'SendComponent',
  methods: {
    async onCommunicate ({ event, data }: PayloadCommunicate) {
      this[`on${event}`](data)
    },
    async onDoneQuestion () {
      this.question = null
    },
    async onNewQuestion (data: MessageQuestion) {
      this.question = data

      this.$nextTick(() => {
        this.input()?.focus()
      })
    },
    async onSend () {
      if (!this.question) return

      if (!this.input()?.value) return

      this.$emit('communicate', {
        to: 'box',
        event: 'ReplyQuestion',
        data: {
          question: this.question,
          value: this.input()!.value
        }
      })
    },
    input (): HTMLInputElement | undefined {
      return this.$el.querySelector('.send-ipt')
    }
  }
})
</script>

<style scoped>
#send {
  @apply m-4 p-2 flex bg-tertiary shadow-md rounded-md;
}

.send-ipt {
  @apply p-2 grow bg-transparent;
}
</style>
